import monitoring from '../monitoring';
import { setInstance } from '../request';
import { CUSTOM_FEED_LAYOUT_IDS, CUSTOM_FEED_TOKEN, POST_LIST_WIDGET_LAYOUT_IDS } from './constants';
import { StyleMapper } from './style-mapper';
import {
  fetchCategories,
  getCustomFeedComponents,
  getRecentPostsComponents,
  layoutMapper,
  mapContentHeight,
  mapMargins,
  mapOneColumnContentHeight,
  mapOneColumnImageRatio,
  mapPostsPerPage,
  mapPostsPerRow,
  mapSideBySideImageRatio,
  updateComponent,
} from './utils';

const mapMinimalLayout = (customFeed, categories, categoryId) => {
  const styleMapper = new StyleMapper(customFeed);

  styleMapper
    .mapStyle('param_number_list-layoutId', 'param_number_layout-post-list-layoutType', { formatter: layoutMapper })
    .mapStyle('param_boolean_widget-isCustomFeedFeaturedPost', 'param_boolean_postListWidgetIsFeatured')
    .mapStyle('param_boolean_blog-isViewCountEnabled', 'param_boolean_blog-mobile-isViewCountEnabled', {
      shouldDelete: false,
    })
    .mapStyle('param_boolean_blog-isCommentCountEnabled', 'param_boolean_blog-mobile-isCommentCountEnabled', {
      shouldDelete: false,
    })
    .mapStyle('param_boolean_blog-isLikeCountEnabled', 'param_boolean_blog-mobile-isLikeCountEnabled', {
      shouldDelete: false,
    })
    .mapFont('param_font_post-titleFont-standard', 'param_font_post-post-list-titleFont')
    .mapFont('param_font_post-titleFont', 'param_font_post-post-list-titleFont')
    .mapFont('param_font_post-descriptionFont-standard', 'param_font_post-post-list-descriptionFont')
    .mapFont('param_font_post-descriptionFont', 'param_font_post-post-list-descriptionFont')
    .mapStyle('param_number_post-borderWidth', 'param_number_post-post-list-borderWidth')
    .mapColor('param_color_post-descriptionColor-standard', 'param_color_post-post-list-descriptionColor')
    .mapColor('param_color_post-descriptionColor', 'param_color_post-post-list-descriptionColor')
    .mapColor('param_color_post-backgroundColor', 'param_color_post-post-list-backgroundColor')
    .mapColor('param_color_widget-backgroundColor', 'param_color_postListWidgetBackgroundColor')
    .mapColor('param_color_post-hoverColor-standard', 'param_color_post-post-list-linkHashtagColor')
    .mapColor('param_color_post-hoverColor', 'param_color_post-post-list-linkHashtagColor')
    .mapColor('param_color_post-titleColor-standard', 'param_color_post-post-list-titleColor')
    .mapColor('param_color_post-titleColor', 'param_color_post-post-list-titleColor')
    .mapColor('param_color_post-borderColor', 'param_color_post-post-list-borderColor')
    .setStyle('param_number_postListWidgetEntityCount', mapPostsPerRow(customFeed))
    .setStyle('param_number_layout-post-list-pg-grid-imageRatio', '1')
    .setStyle('param_number_layout-post-list-pg-grid-layoutPostSize', '292')
    .setStyle('param_number_layout-post-list-pg-grid-layoutSpacing', '32')
    .setStyle('param_number_layout-post-list-pg-grid-contentHeight', mapContentHeight(customFeed))
    .setStyle('param_number_layout-post-list-pg-grid-margins', mapMargins(customFeed))
    .setStyle('param_number_layout-post-list-pg-grid-sidesPadding', '24')
    .setStyle('param_number_layout-post-list-pg-grid-titleLineCount', '2')
    .setStyle('param_boolean_blog-isCoverImageEnabled', 'true')
    .setStyle('param_boolean_blog-isPostDescriptionEnabled', 'false')
    .setStyle('param_boolean_blog-isAuthorNameEnabled', 'false')
    .setStyle('param_boolean_blog-isPostPublishDateEnabled', 'false')
    .setStyle('param_boolean_blog-isReadingTimeEnabled', 'false')
    .setStyle('param_number_postListWidgetPaginationType', '0')
    .setStyle('param_number_post-pg-grid-post-list-mobile-titleFontSize', '22')
    .setStyle('param_number_list-mobile-layoutId', '16')
    .setStyle('param_boolean_blog-mobile-isMobileDisplaySettingsEnabled', 'true')
    .setStyle('param_boolean_blog-mobile-isMobileLayoutSettingsEnabled', 'true')
    .setStyle('param_boolean_blog-mobile-isCoverImageEnabled', 'true')
    .setStyle('param_boolean_blog-mobile-isPostDescriptionEnabled', 'true')
    .setStyle('param_boolean_blog-mobile-isAuthorNameEnabled', 'true')
    .setStyle('param_boolean_blog-mobile-isPostPublishDateEnabled', 'true')
    .setStyle('param_boolean_blog-mobile-isReadingTimeEnabled', 'false')
    .setStyle(
      'param_number_layout-mobile-post-list-pg-grid-contentHeight',
      mapOneColumnContentHeight(customFeed, { defaultHeight: 235, isMobile: true }),
    )
    .mapLayoutWidth()
    .mapCategory(categories, categoryId);

  return styleMapper.build();
};

const mapMagazineLayout = (customFeed, categories, categoryId) => {
  const styleMapper = new StyleMapper(customFeed);

  styleMapper
    .mapStyle('param_number_list-layoutId', 'param_number_layout-post-list-layoutType', { formatter: layoutMapper })
    .mapStyle('param_boolean_widget-isCustomFeedFeaturedPost', 'param_boolean_postListWidgetIsFeatured')
    .mapFont('param_font_post-titleFont-standard', 'param_font_post-post-list-titleFont')
    .mapFont('param_font_post-titleFont', 'param_font_post-post-list-titleFont')
    .mapFont('param_font_post-descriptionFont-standard', 'param_font_post-post-list-descriptionFont')
    .mapFont('param_font_post-descriptionFont', 'param_font_post-post-list-descriptionFont')
    .mapStyle('param_number_post-borderWidth', 'param_number_post-post-list-borderWidth')
    .mapColor('param_color_post-descriptionColor-standard', 'param_color_post-post-list-TOI-descriptionColor')
    .mapColor('param_color_post-descriptionColor', 'param_color_post-post-list-TOI-descriptionColor')
    .mapColor('param_color_post-backgroundColor', 'param_color_post-post-list-TOI-backgroundColor')
    .mapColor('param_color_widget-backgroundColor', 'param_color_postListWidgetBackgroundColor')
    .mapColor('param_color_post-hoverColor-standard', 'param_color_post-post-list-linkHashtagColor')
    .mapColor('param_color_post-hoverColor', 'param_color_post-post-list-linkHashtagColor')
    .mapColor('param_color_post-titleColor-standard', 'param_color_post-post-list-TOI-titleColor')
    .mapColor('param_color_post-titleColor', 'param_color_post-post-list-TOI-titleColor')
    .mapColor('param_color_post-borderColor', 'param_color_post-post-list-borderColor')
    .mapColor('param_color_post-imageOverlayColor', 'param_color_post-post-list-overlayColor')
    .setStyle('param_number_postListWidgetEntityCount', mapPostsPerRow(customFeed, 2))
    .setStyle('param_number_layout-post-list-pg-text-on-image-medium-imageRatio', '3')
    .setStyle('param_number_layout-post-list-pg-text-on-image-medium-layoutPostSize', '454')
    .setStyle('param_number_layout-post-list-pg-text-on-image-medium-layoutSpacing', '32')
    .setStyle('param_number_layout-post-list-pg-text-on-image-medium-margins', mapMargins(customFeed))
    .setStyle('param_number_layout-post-list-pg-text-on-image-medium-sidesPadding', '30')
    .setStyle('param_number_layout-post-list-pg-text-on-image-medium-titleLineCount', '2')
    .setStyle('param_boolean_blog-isCoverImageEnabled', 'true')
    .setStyle('param_boolean_blog-isPostDescriptionEnabled', 'false')
    .setStyle('param_boolean_blog-isAuthorPictureEnabled', 'false')
    .setStyle('param_number_postListWidgetPaginationType', '0')
    .setStyle('param_number_post-pg-text-on-image-medium-post-list-mobile-titleFontSize', '22')
    .mapLayoutWidth(454)
    .mapCategory(categories, categoryId);

  return styleMapper.build();
};

const mapOneColumnLayout = (customFeed, categories, categoryId) => {
  const styleMapper = new StyleMapper(customFeed);

  styleMapper
    .mapStyle('param_number_list-layoutId', 'param_number_layout-post-list-layoutType', { formatter: layoutMapper })
    .mapStyle('param_boolean_widget-isCustomFeedFeaturedPost', 'param_boolean_postListWidgetIsFeatured')
    .mapStyle('param_boolean_blog-isViewCountEnabled', 'param_boolean_blog-mobile-isViewCountEnabled', {
      shouldDelete: false,
    })
    .mapStyle('param_boolean_blog-isCommentCountEnabled', 'param_boolean_blog-mobile-isCommentCountEnabled', {
      shouldDelete: false,
    })
    .mapStyle('param_boolean_blog-isLikeCountEnabled', 'param_boolean_blog-mobile-isLikeCountEnabled', {
      shouldDelete: false,
    })
    .mapStyle('param_boolean_blog-isAuthorNameEnabled', 'param_boolean_blog-mobile-isAuthorNameEnabled', {
      shouldDelete: false,
    })
    .mapStyle('param_boolean_blog-isPostPublishDateEnabled', 'param_boolean_blog-mobile-isPostPublishDateEnabled', {
      shouldDelete: false,
    })
    .mapStyle('param_boolean_blog-isReadingTimeEnabled', 'param_boolean_blog-mobile-isReadingTimeEnabled', {
      shouldDelete: false,
    })
    .mapFont('param_font_post-titleFont-standard', 'param_font_post-post-list-titleFont')
    .mapFont('param_font_post-titleFont', 'param_font_post-post-list-titleFont')
    .mapFont('param_font_post-descriptionFont-standard', 'param_font_post-post-list-descriptionFont')
    .mapFont('param_font_post-descriptionFont', 'param_font_post-post-list-descriptionFont')
    .mapFont('param_font_post-descriptionFont-standard', 'param_font_post-pg-grid-post-list-descriptionFont')
    .mapFont('param_font_post-descriptionFont', 'param_font_post-pg-grid-post-list-descriptionFont')
    .mapStyle('param_number_post-borderWidth', 'param_number_post-post-list-borderWidth')
    .mapStyle('param_number_list-numberOfPosts', 'param_number_postListWidgetPostsPerPage')
    .mapColor('param_color_post-descriptionColor-standard', 'param_color_post-post-list-descriptionColor')
    .mapColor('param_color_post-descriptionColor', 'param_color_post-post-list-descriptionColor')
    .mapColor('param_color_post-backgroundColor', 'param_color_post-post-list-backgroundColor')
    .mapColor('param_color_widget-backgroundColor', 'param_color_postListWidgetBackgroundColor')
    .mapColor('param_color_post-hoverColor-standard', 'param_color_post-post-list-linkHashtagColor')
    .mapColor('param_color_post-hoverColor', 'param_color_post-post-list-linkHashtagColor')
    .mapColor('param_color_post-titleColor-standard', 'param_color_post-post-list-titleColor')
    .mapColor('param_color_post-titleColor', 'param_color_post-post-list-titleColor')
    .mapColor('param_color_post-borderColor', 'param_color_post-post-list-borderColor')
    .setStyle('param_number_postListWidgetEntityCount', mapPostsPerPage(customFeed))
    .setStyle('param_number_layout-post-list-pg-one-column-imageRatio', mapOneColumnImageRatio(customFeed))
    .setStyle('param_number_layout-post-list-pg-one-column-layoutPostSize', '1000')
    .setStyle('param_number_layout-post-list-pg-one-column-layoutSpacing', '32')
    .setStyle('param_number_layout-post-list-pg-one-column-contentHeight', mapOneColumnContentHeight(customFeed))
    .setStyle('param_number_layout-post-list-pg-one-column-margins', mapMargins(customFeed))
    .setStyle('param_number_layout-post-list-pg-one-column-sidesPadding', '24')
    .setStyle('param_number_layout-post-list-pg-one-column-titleLineCount', '2')
    .setStyle('param_number_layout-post-list-pg-one-column-descriptionLineCount', '2')
    .setStyle('param_boolean_blog-isCoverImageEnabled', 'true')
    .setStyle('param_boolean_blog-isPostDescriptionEnabled', 'true')
    .setStyle('param_number_postListWidgetPaginationType', '0')
    .setStyle('param_number_list-mobile-layoutId', '16')
    .setStyle('param_number_post-pg-grid-post-list-mobile-titleFontSize', '22')
    .setStyle('param_number_layout-mobile-post-list-pg-grid-mobile-titleLineCount', '2')
    .setStyle('param_number_layout-mobile-post-list-pg-grid-imageCropType', '1')
    .setStyle('param_number_layout-mobile-post-list-pg-grid-imageRatio', '2')
    .setStyle('param_boolean_blog-mobile-isMobileLayoutSettingsEnabled', 'true')
    .setStyle('param_boolean_blog-mobile-isMobileDisplaySettingsEnabled', 'true')
    .setStyle('param_boolean_blog-mobile-isCoverImageEnabled', 'true')
    .setStyle('param_boolean_blog-mobile-isPostDescriptionEnabled', 'true')
    .setStyle(
      'param_number_layout-mobile-post-list-pg-grid-contentHeight',
      mapOneColumnContentHeight(customFeed, { defaultHeight: 236, isMobile: true }),
    )
    .mapCategory(categories, categoryId);

  return styleMapper.build();
};

const mapSideBySideLayout = (customFeed, categories, categoryId) => {
  const styleMapper = new StyleMapper(customFeed);

  styleMapper
    .mapStyle('param_number_list-layoutId', 'param_number_layout-post-list-layoutType', { formatter: layoutMapper })
    .mapStyle('param_boolean_widget-isCustomFeedFeaturedPost', 'param_boolean_postListWidgetIsFeatured')
    .mapStyle('param_boolean_blog-isViewCountEnabled', 'param_boolean_blog-mobile-isViewCountEnabled', {
      shouldDelete: false,
    })
    .mapStyle('param_boolean_blog-isCommentCountEnabled', 'param_boolean_blog-mobile-isCommentCountEnabled', {
      shouldDelete: false,
    })
    .mapStyle('param_boolean_blog-isLikeCountEnabled', 'param_boolean_blog-mobile-isLikeCountEnabled', {
      shouldDelete: false,
    })
    .mapStyle('param_boolean_blog-isAuthorNameEnabled', 'param_boolean_blog-mobile-isAuthorNameEnabled', {
      shouldDelete: false,
    })
    .mapStyle('param_boolean_blog-isPostPublishDateEnabled', 'param_boolean_blog-mobile-isPostPublishDateEnabled', {
      shouldDelete: false,
    })
    .mapStyle('param_boolean_blog-isReadingTimeEnabled', 'param_boolean_blog-mobile-isReadingTimeEnabled', {
      shouldDelete: false,
    })
    .mapFont('param_font_post-titleFont-standard', 'param_font_post-post-list-titleFont')
    .mapFont('param_font_post-titleFont', 'param_font_post-post-list-titleFont')
    .mapFont('param_font_post-descriptionFont-standard', 'param_font_post-post-list-descriptionFont')
    .mapFont('param_font_post-descriptionFont', 'param_font_post-post-list-descriptionFont')
    .mapStyle('param_number_post-borderWidth', 'param_number_post-post-list-borderWidth')
    .mapStyle('param_number_list-numberOfRows', 'param_number_postListWidgetPostsPerPage')
    .mapColor('param_color_post-descriptionColor-standard', 'param_color_post-post-list-descriptionColor')
    .mapColor('param_color_post-backgroundColor', 'param_color_post-post-list-backgroundColor')
    .mapColor('param_color_widget-backgroundColor', 'param_color_postListWidgetBackgroundColor')
    .mapColor('param_color_post-hoverColor-standard', 'param_color_post-post-list-linkHashtagColor')
    .mapColor('param_color_post-hoverColor', 'param_color_post-post-list-linkHashtagColor')
    .mapColor('param_color_post-titleColor-standard', 'param_color_post-post-list-titleColor')
    .mapColor('param_color_post-titleColor', 'param_color_post-post-list-titleColor')
    .mapColor('param_color_post-borderColor', 'param_color_post-post-list-borderColor')
    .setStyle('param_number_postListWidgetEntityCount', mapPostsPerPage(customFeed))
    .setStyle('param_number_layout-post-list-pg-side-by-side-imageRatio', mapSideBySideImageRatio(customFeed))
    .setStyle('param_number_layout-post-list-pg-side-by-side-layoutSpacing', '32')
    .setStyle('param_number_layout-post-list-pg-side-by-side-margins', mapMargins(customFeed))
    .setStyle('param_number_layout-post-list-pg-side-by-side-sidesPadding', '48')
    .setStyle('param_number_layout-post-list-pg-side-by-side-titleLineCount', '3')
    .setStyle('param_number_layout-post-list-pg-side-by-side-descriptionLineCount', '8')
    .setStyle('param_boolean_blog-isCoverImageEnabled', 'true')
    .setStyle('param_boolean_blog-isPostDescriptionEnabled', 'true')
    .setStyle('param_number_layout-post-list-pg-side-by-side-imageProportions', '52')
    .setStyle('param_number_postListWidgetPaginationType', '0')
    .setStyle('param_number_list-mobile-layoutId', '16')
    .setStyle('param_number_post-pg-grid-post-list-mobile-titleFontSize', '22')
    .setStyle('param_number_layout-mobile-post-list-pg-grid-mobile-titleLineCount', '2')
    .setStyle('param_number_layout-mobile-post-list-pg-grid-imageRatio', '2')
    .setStyle('param_number_layout-mobile-post-list-pg-grid-imageCropType', '1')
    .setStyle('param_boolean_blog-mobile-isMobileDisplaySettingsEnabled', 'true')
    .setStyle('param_boolean_blog-mobile-isMobileLayoutSettingsEnabled', 'true')
    .setStyle('param_boolean_blog-mobile-isCoverImageEnabled', 'true')
    .setStyle('param_boolean_blog-mobile-isPostDescriptionEnabled', 'true')
    .setStyle(
      'param_number_layout-mobile-post-list-pg-grid-contentHeight',
      mapOneColumnContentHeight(customFeed, { defaultHeight: 236, isMobile: true }),
    )
    .mapCategory(categories, categoryId);

  return styleMapper.build();
};

const mapPostcardLayout = (customFeed, categories, categoryId) => {
  const styleMapper = new StyleMapper(customFeed);

  styleMapper
    .mapStyle('param_number_list-layoutId', 'param_number_layout-post-list-layoutType', { formatter: layoutMapper })
    .mapStyle('param_boolean_widget-isCustomFeedFeaturedPost', 'param_boolean_postListWidgetIsFeatured')
    .mapFont('param_font_post-titleFont-standard', 'param_font_post-post-list-titleFont')
    .mapFont('param_font_post-titleFont', 'param_font_post-post-list-titleFont')
    .mapFont('param_font_post-descriptionFont-standard', 'param_font_post-post-list-descriptionFont')
    .mapFont('param_font_post-descriptionFont', 'param_font_post-post-list-descriptionFont')
    .mapStyle('param_number_post-borderWidth', 'param_number_post-post-list-borderWidth')
    .mapColor('param_color_post-descriptionColor-standard', 'param_color_post-post-list-TOI-descriptionColor')
    .mapColor('param_color_post-descriptionColor', 'param_color_post-post-list-TOI-descriptionColor')
    .mapColor('param_color_post-backgroundColor', 'param_color_post-post-list-TOI-backgroundColor')
    .mapColor('param_color_widget-backgroundColor', 'param_color_postListWidgetBackgroundColor')
    .mapColor('param_color_post-hoverColor-standard', 'param_color_post-post-list-linkHashtagColor')
    .mapColor('param_color_post-hoverColor', 'param_color_post-post-list-linkHashtagColor')
    .mapColor('param_color_post-titleColor-standard', 'param_color_post-post-list-TOI-titleColor')
    .mapColor('param_color_post-titleColor', 'param_color_post-post-list-TOI-titleColor')
    .mapColor('param_color_post-borderColor', 'param_color_post-post-list-borderColor')
    .mapColor('param_color_post-imageOverlayColor', 'param_color_post-post-list-overlayColor')
    .setStyle('param_number_postListWidgetEntityCount', mapPostsPerRow(customFeed))
    .setStyle('param_number_layout-post-list-pg-text-on-image-medium-imageRatio', '3')
    .setStyle('param_number_layout-post-list-pg-text-on-image-medium-layoutPostSize', '320')
    .setStyle('param_number_layout-post-list-pg-text-on-image-medium-layoutSpacing', '32')
    .setStyle('param_number_layout-post-list-pg-text-on-image-medium-margins', mapMargins(customFeed))
    .setStyle('param_number_layout-post-list-pg-text-on-image-medium-sidesPadding', '24')
    .setStyle('param_number_layout-post-list-pg-text-on-image-medium-titleLineCount', '2')
    .setStyle('param_boolean_blog-isCoverImageEnabled', 'true')
    .setStyle('param_boolean_blog-isPostDescriptionEnabled', 'false')
    .setStyle('param_boolean_blog-isAuthorPictureEnabled', 'false')
    .setStyle('param_number_postListWidgetPaginationType', '0')
    .setStyle('param_number_post-pg-text-on-image-medium-post-list-mobile-titleFontSize', '22')
    .mapLayoutWidth()
    .mapCategory(categories, categoryId);

  return styleMapper.build();
};

const mapRecentPosts = (recentPosts) => {
  const styleMapper = new StyleMapper(recentPosts);

  styleMapper
    .mapStyle('param_number_post-cardBorderWidth', 'param_number_post-post-list-borderWidth')
    .mapStyle('param_boolean_blog-isViewCountEnabled', 'param_boolean_blog-mobile-isViewCountEnabled', {
      shouldDelete: false,
    })
    .mapStyle('param_boolean_blog-isCommentCountEnabled', 'param_boolean_blog-mobile-isCommentCountEnabled', {
      shouldDelete: false,
    })
    .mapStyle('param_boolean_blog-isLikeCountEnabled', 'param_boolean_blog-mobile-isLikeCountEnabled', {
      shouldDelete: false,
    })
    .mapColor('param_color_post-descriptionColor', 'param_color_post-post-list-descriptionColor')
    .mapColor('param_color_post-cardBackgroundColor', 'param_color_post-post-list-backgroundColor')
    .mapColor('param_color_widget-backgroundColor', 'param_color_postListWidgetBackgroundColor')
    .mapColor('param_color_post-hoverColor', 'param_color_post-post-list-linkHashtagColor')
    .mapColor('param_color_post-titleColor', 'param_color_post-post-list-titleColor')
    .mapColor('param_color_post-cardBorderColor', 'param_color_post-post-list-borderColor')
    .mapFont('param_font_post-titleFont', 'param_font_post-post-list-titleFont', { size: 18, preset: 'Custom' })
    .mapFont('param_font_post-titleFont', 'param_font_post-pg-grid-post-list-titleFont', { size: 18, preset: 'Custom' })
    .setStyle('param_number_layout-post-list-layoutType', POST_LIST_WIDGET_LAYOUT_IDS.editorial)
    .setStyle('param_number_layout-mobile-post-list-layoutType', POST_LIST_WIDGET_LAYOUT_IDS.slider)
    .setStyle('param_boolean_blog-isCoverImageEnabled', 'true')
    .setStyle('param_number_postListWidgetPaginationType', '0')
    .setStyle('param_boolean_blog-isPostDescriptionEnabled', 'false')
    .setStyle('param_boolean_blog-isAuthorNameEnabled', 'false')
    .setStyle('param_boolean_blog-isPostPublishDateEnabled', 'false')
    .setStyle('param_boolean_blog-isReadingTimeEnabled', 'false')
    .setStyle('param_number_postListWidgetEntityCount', '3')
    .setStyle('param_number_layout-post-list-pg-grid-titleLineCount', '2')
    .setStyle('param_number_layout-post-list-pg-grid-imageRatio', '1')
    .setStyle('param_number_layout-post-list-pg-grid-layoutPostSize', '292')
    .setStyle('param_number_layout-post-list-pg-grid-layoutSpacing', '32')
    .setStyle('param_number_layout-post-list-pg-side-by-side-margins', '20')
    .setStyle('param_number_layout-post-list-pg-grid-sidesPadding', '24')
    .setStyle(
      'param_number_layout-post-list-pg-grid-contentHeight',
      mapOneColumnContentHeight(recentPosts, { defaultHeight: 101 }),
    )
    .setStyle('param_number_list-mobile-layoutId', '18')
    .setStyle('param_boolean_blog-mobile-isMobileLayoutSettingsEnabled', 'true')
    .setStyle(
      'param_number_layout-mobile-post-list-slider-contentHeight',
      mapOneColumnContentHeight(recentPosts, { defaultHeight: 135, isMobile: true }),
    );

  return styleMapper.build();
};

export const migrateCustomFeed = async (sdk) => {
  try {
    const layoutMap = {
      [CUSTOM_FEED_LAYOUT_IDS.minimal]: mapMinimalLayout,
      [CUSTOM_FEED_LAYOUT_IDS.magazine]: mapMagazineLayout,
      [CUSTOM_FEED_LAYOUT_IDS.oneColumn]: mapOneColumnLayout,
      [CUSTOM_FEED_LAYOUT_IDS.sideBySide]: mapSideBySideLayout,
      [CUSTOM_FEED_LAYOUT_IDS.postcard]: mapPostcardLayout,
    };

    const instance = await sdk.document.info.getAppInstance();
    if (!instance) {
      return;
    }

    setInstance(instance);
    const categories = await fetchCategories();
    const customFeedComponents = await getCustomFeedComponents(sdk);
    await Promise.all(customFeedComponents.map(({ customFeed, categoryId }) => {
      const layoutId = customFeed?.style?.style?.properties?.['param_number_list-layoutId'];
      const mapFunction = layoutMap[layoutId];

      if (!mapFunction) {
        return;
      }

      const componentToReplace = mapFunction(customFeed, categories, categoryId);
      return updateComponent(sdk, componentToReplace);
    }));

    const recentPostsComponents = await getRecentPostsComponents(sdk);
    await Promise.all(recentPostsComponents.map((recentPosts) => {
      const componentToReplace = mapRecentPosts(recentPosts);
      return updateComponent(sdk, componentToReplace);
    }));

    await sdk.tpa.app.refreshApp(CUSTOM_FEED_TOKEN);
  } catch (e) {
    monitoring.reportError(e);
  }
};
