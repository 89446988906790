import { MANAGE_POSTS_EVENT, DELETE_BLOG_EVENT, APP_ACTION_EVENTS, EDITOR_ACTION_TYPE } from '../constants/events';
import translation from './translation';

const { translate } = translation;
const pagesPanelUrl = 'https://editor.wix.com/communities-blog-pages-panel';

const getAppManifest = (sdkScriptSrc, appToken) => {
  const getPanelUrl = (slug) =>
    `${pagesPanelUrl}/${slug}?wix-editor-script=${sdkScriptSrc}&token=${appToken}`;

  return {
    pages: {
      pageActions: {
        default: [
          'Pages_Actions_Page_Rename',
          {
            title: translate('app-manifest.delete-page'),
            event: DELETE_BLOG_EVENT,
            icon: 'deleteRadio',
          },
        ],
        postPage: [],
      },
      pageSettings: {
        default: [
          {
            title: translate('app-manifest.page-info'),
            type: 'page_info',
          },
          {
            title: translate('app-manifest.layout'),
            type: 'layout',
          },
          {
            title: translate('app-manifest.permissions'),
            type: 'permissions',
          },
          {
            title: translate('app-manifest.seo'),
            type: 'seo',
          },
        ],
        postPage: [
          {
            title: translate('app-manifest.page-info'),
            url: getPanelUrl('page-info'),
            type: 'page_info',
          },
          {
            title: translate('app-manifest.layout'),
            type: 'layout',
          },
          {
            title: translate('app-manifest.permissions'),
            type: 'permissions',
          },
          {
            title: translate('app-manifest.seo'),
            url: getPanelUrl('seo'),
            type: 'seo',
          },
        ],
      },
      applicationSettings: {
        default: {
          displayName: translate('app-manifest.blog-pages'),
        },
      },
      applicationActions: {
        default: {
          defaultValues: [
            {
              title: translate('app-manifest.manage-posts'),
              event: MANAGE_POSTS_EVENT,
              icon: 'settingsAction',
            },
            {
              title: translate('app-manifest.delete-blog'),
              event: DELETE_BLOG_EVENT,
              icon: 'deleteRadio',
            },
          ],
        },
      },
      pageDescriptors: {
        default: {
          icon: 'blogPageType',
        },
      },
    },
    appDescriptor: {
      mainActions: [
        {
          title: translate('app-manifest.app-descriptor.create-post'),
          actionId: APP_ACTION_EVENTS.CREATE_POST,
          icon: 'appManager_addElementsAction',
        },
        {
          title: translate('app-manifest.app-descriptor.manage-posts'),
          actionId: APP_ACTION_EVENTS.MANAGE_POSTS,
          icon: 'appManager_settingsAction',
        },
      ],
      customActions: [
        {
          title: translate('app-manifest.app-descriptor.manage-blog-pages'),
          actionId: APP_ACTION_EVENTS.OPEN_BLOG_PAGES_PANEL,
          icon: 'appManager_pagesAction',
          type: EDITOR_ACTION_TYPE,
        },
        {
          title: translate('app-manifest.app-descriptor.open-page-settings'),
          actionId: APP_ACTION_EVENTS.OPEN_POST_PAGE_SETTINGS,
          icon: 'customizeDesignButtonBrush',
          type: EDITOR_ACTION_TYPE,
        },
        {
          title: translate('app-manifest.app-descriptor.add-blog-elements'),
          actionId: APP_ACTION_EVENTS.OPEN_BLOG_ADD_PANEL,
          icon: 'appManager_addElementsAction',
          type: EDITOR_ACTION_TYPE,
        },
        {
          title: translate('app-manifest.app-descriptor.read-blogging-guide'),
          icon: 'sparkling-bulb-reg',
          type: EDITOR_ACTION_TYPE,
          url: 'https://www.wix-blog-community.com/guide',
        },
      ],
      defaultActions: {
        upgrade: 'SITE_UPGRADE' | 'APP_UPGRADE',
        learnMoreKB: 'a7eeebda-8828-4a49-ba7e-d9b656caa197',
      },
    },
  };
};

export default getAppManifest;
